import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {DashboardPage} from './pages/DashboardPage'
import {AuthPage} from './pages/AuthPage'
import {SlackTimelinePage} from "./pages/SlackTimelinePage"

export const useRoutes = isAuthenticated => {
    if (isAuthenticated) {
        return (
            <Switch>
                <Route path="/dashboard">
                    <DashboardPage/>
                </Route>
                <Route path="/activities" exact>
                    <SlackTimelinePage/>
                </Route>
                <Redirect to="/dashboard"/>
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path="/" exact>
                <AuthPage/>
            </Route>
            <Route path="/timeline" exact>
                <SlackTimelinePage/>
            </Route>
            <Redirect to="/"/>
        </Switch>
    )
}
