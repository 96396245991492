import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {StartPreloader} from '../components/StartPreloader'
import {ProjectsTabs} from '../components/ProjectsTabs'
import M from 'materialize-css'
import config from '../config/config'

export const DashboardPage = () => {
    const [projects, setProjects] = useState(false)
    const [gitProjects, setGitProjects] = useState(false)
    const [pleskFiles, setPleskFiles] = useState(false)
    const [pleskDomains, setPleskDomains] = useState(false)
    const [pleskRepositories, setPleskRepositories] = useState(false)
    const [teamworkProjects, setTeamworkProjects] = useState(false)
    const [teamworkProjectsNoTag, setTeamworkProjectsNoTag] = useState(false)
    const [teamworkProjectsInactive, setTeamworkProjectsInactive] = useState(false)
    const [teamworkTags, setTeamworkTags] = useState(false)
    const [pleskDatabases, setPleskDatabases] = useState(false)
    const [loading, setLoading] = useState(true)
    const {request} = useHttp()
    const {token} = useContext(AuthContext)
    const whiteList = ['plesk.wdscode.guru', 'test.wdscode.guru', config.trash_default_path, config.site_name]
    const excludeTag = 107622 // Id of tag "norelation" in Teamwork

    const getGitProjects = async () => {
        try {
            // Todo set some auth for this side
            const fetched = await request('/api/github/projects', 'GET', null, {
                Authorization: `Bearer ${token}`
            })
            setGitProjects(JSON.parse(JSON.stringify(fetched)))
        } catch (e) {
        }
    }

    const getPleskFiles = async () => {
        try {
            const fetched = await request('/api/server/files', 'GET')
            setPleskFiles(Object.assign({}, fetched))
        } catch (e) {
        }
    }

    const getPleskDomains = async () => {
        try {
            const fetched = await request('/api/server/domains', 'GET')
            setPleskDomains(JSON.parse(JSON.stringify(fetched)))
        } catch (e) {
        }
    }

    const getPleskRepositories = async () => {
        try {
            const fetched = await request('/api/server/repositories', 'GET')
            setPleskRepositories(JSON.parse(JSON.stringify(fetched)))
        } catch (e) {
        }
    }

    const getTeamworkProjects = async () => {
        try {
            const fetched = await request('https://iigglobal.teamwork.com/projects/api/v3/projects.json?pageSize=500&fields[tags]=[name]', 'GET', null, {
                Authorization: `BASIC ${config.teamwork_auth}`
            })
            let inactiveProjects = [],
                tagProjects = [],
                noTagProjects = [],
                today = new Date(),
                project_inactive = today.setMonth(today.getMonth() - 2)
            fetched['projects'].map(function (el) {
                // Check if project has relation
                if (el.tagIds !== null && el.tagIds.indexOf(excludeTag) === -1) {
                    tagProjects.push(el)
                }
                // Check if project is active
                if (new Date(el.updatedAt) < project_inactive) {
                    inactiveProjects.push(el)
                } else if (el.tagIds === null) {
                    noTagProjects.push(el)
                }
            })

            setTeamworkProjects(JSON.parse(JSON.stringify(tagProjects)))
            setTeamworkProjectsNoTag(JSON.parse(JSON.stringify(noTagProjects)))
            setTeamworkProjectsInactive(JSON.parse(JSON.stringify(inactiveProjects)))
        } catch (e) {
        }
    }

    const getTeamworkTags = async () => {
        try {
            const fetched = await request('https://iigglobal.teamwork.com/projects/api/v3/tags.json', 'GET', null, {
                Authorization: `BASIC ${config.teamwork_auth}`
            })
            let tags = {}
            fetched['tags'].map(function (el) {
                tags[el.id] = el.name
            })
            setTeamworkTags(Object.assign({}, tags))
        } catch (e) {
        }
    }

    const getDatabases = async () => {
        try {
            const fetched = await request('/api/server/databases', 'GET')
            setPleskDatabases(Object.assign({}, fetched))
        } catch (e) {
        }
    }

    const fetchProjects = useCallback(async () => {
        getGitProjects()
        getPleskFiles()
        getPleskDomains()
        getPleskRepositories()
        getTeamworkProjects()
        getTeamworkTags()
        getDatabases() // Custom route
    }, [token, request])

    useEffect(() => {
        fetchProjects()
    }, [fetchProjects])

    useEffect(() => {
        if (gitProjects !== false &&
            pleskFiles !== false &&
            pleskDomains !== false &&
            pleskRepositories !== false &&
            teamworkProjects !== false &&
            teamworkProjectsNoTag !== false &&
            teamworkProjectsInactive !== false &&
            teamworkTags !== false &&
            pleskDatabases !== false) {
            console.log('--------------------------------------')
            console.log('Git Projects')
            console.log(gitProjects)
            console.log('Plesk Files')
            console.log(pleskFiles)
            console.log('Plesk Domain')
            console.log(pleskDomains)
            console.log('Plesk repositories')
            console.log(pleskRepositories)
            console.log('Teamwork Projects')
            console.log(teamworkProjects)
            console.log('Teamwork without tags Projects')
            console.log(teamworkProjectsNoTag)
            console.log('Teamwork Projects inactive')
            console.log(teamworkProjectsInactive)
            console.log('Teamwork Tags')
            console.log(teamworkTags)
            console.log('Databases')
            console.log(pleskDatabases)

            let data = pleskFiles
            let databases = pleskDatabases
            let git = {} // auxiliary array for git
            let domainToFolder = {} // auxiliary array for getting folder name by domain name
            let db // auxiliary array for databases
            let today = new Date() // get today date
            let project_inactive = today.setMonth(today.getMonth() - 2) // Inactive projects and teamwork date
            let git_inactive = today.setMonth(today.getMonth() - 4) // Inactive git projects date,
            console.log(1)
            // rebuild array from Git
            gitProjects.forEach(function (item) {
                git[item.ssh_url_to_repo.split('/').pop()] = item
            })
            console.log(2)
            // join files on the server with Domains on the server
            pleskDomains.forEach(function (item) {
                let name = ''
                if(item.www_root.indexOf(config.main_folder) !== -1) {
                    name = item.www_root.split(config.main_folder)[1].split('/')[0]
                } else {
                    name = item.www_root.split(config.folder_name)[1].split('/')[0]
                }

                domainToFolder[item.name] = name

                // get domains database
                if (pleskDatabases[item.id] !== undefined) {
                    db = pleskDatabases[item.id]
                    if (databases[item.id].default_user_id !== 0) {
                        delete databases[item.id]
                    }
                } else {
                    db = false
                }

                // remove if in white list
                if (whiteList.indexOf(name) !== -1) {
                    delete data['folders'][name]
                } else {
                    // if empty
                    if (data['folders'][name] === undefined) {
                        data['folders'][name] = {
                            'repository': false,
                            'domain': item,
                            'git': false,
                            'database': db,
                            'teamwork': false,
                            'size': false,
                            'move': 'empty'
                        }
                    } else {
                        data['folders'][name] = {
                            'repository': false,
                            'domain': item,
                            'git': false,
                            'database': db,
                            'teamwork': false,
                            'size': false
                        }
                    }
                }
            })
            console.log(3)
            // join files on the server with Repositories on the server
            pleskRepositories.forEach(function (item) {
                let path = item['domain'][0]
                let remoteUrl = item['remote-url'][0].split('/').pop()
                if (data['folders'].hasOwnProperty(path)) {
                    data['folders'][path]['repository'] = item
                }
                if (git[remoteUrl] === undefined) {
                    data['folders'][path]['move'] = 'error_git'
                } else {
                    if (git[remoteUrl].visibility !== 'private') {
                        data['folders'][path]['move'] = 'privacy'
                    }
                    if (whiteList.indexOf(path) === -1) {
                        data['folders'][path]['git'] = git[remoteUrl]
                        delete git[remoteUrl]
                    }
                }
            })
            console.log(4)
            // Check for inactive git projects
            for (const [key, obj] of Object.entries(git)) {
                if (new Date(obj['last_activity_at']) < git_inactive) {
                    data['inactive_projects']['gits'][key] = obj
                    delete git[key]
                }
            }
            console.log(5)
            // Set no used Git projects
            data['unused_git'] = git
            // Set no used and without user databases
            for (const [key, obj] of Object.entries(databases)) {
                if (obj.default_user_id === 0) {
                    data['no_user_db'][key] = obj
                } else if (obj.domain_related === false) {
                    data['unused_db'][key] = obj
                }
            }
            console.log(6)
            // Set Teamwork to projects
            let flag = false
            data['tw_inactive'] = teamworkProjectsInactive
            data['tw_no_relation']['teamwork'] = teamworkProjectsNoTag
            teamworkProjects.forEach(function (item) {
                flag = false
                item['tagIds'].forEach(function (tag) {
                    if (data['folders'][domainToFolder[teamworkTags[tag]]] !== undefined) {
                        data['folders'][domainToFolder[teamworkTags[tag]]]['teamwork'] = item
                        flag = true
                    }
                })
                if (flag === false) {
                    data['tw_no_relation']['teamwork'].push(item)
                }
            })
            console.log(7)
            // Move projects to categories
            for (const [key, obj] of Object.entries(data['folders'])) {
                // set sizes for all projects
                if (obj !== false) {
                    data['folders'][key]['size'] = data['sizes'][key]
                }

                if (obj['teamwork'] === false) {
                    if (obj['repository'] === false || obj['git'] === false) {
                        data['tw_no_relation']['ftps'][key] = obj
                    } else {
                        data['tw_no_relation']['projects'][key] = obj
                    }
                    delete data['folders'][key]
                } else if (obj['move'] !== undefined) {
                    if (obj['move'] === 'privacy') {
                        data[obj['move']]['projects'][key] = obj
                    } else {
                        data[obj['move']][key] = obj
                    }
                    delete data['folders'][key]
                } else if (obj !== false &&
                    obj['git'] !== false &&
                    new Date(obj['git']['last_activity_at']) < project_inactive) {
                    data['inactive_projects']['projects'][key] = obj
                    delete data['folders'][key]
                } else if (obj !== false &&
                    obj['git'] === false &&
                    obj['teamwork'] !== false &&
                    new Date(obj['teamwork']['updatedAt']) < project_inactive) {
                    data['inactive_projects']['ftps'][key] = obj
                    delete data['folders'][key]
                } else if (obj['repository'] === false) {
                    data['ftp'][key] = obj
                    delete data['folders'][key]
                } else if (obj === false) {
                    data['trash'][key] = {
                        'type': 'folder',
                        'size': data['sizes'][key],
                        'path': config.main_folder + key,
                        'domain': config.trash_default_path
                    }
                    delete data['folders'][key]
                }
            }
            console.log(8)
            // Move gits to categories
            for (const [key, obj] of Object.entries(data['unused_git'])) {
                if (obj['visibility'] !== 'private') {
                    data['privacy']['gits'][key] = obj
                    delete data['unused_git'][key]
                }
            }
            setProjects(data)
            setLoading(false)
            console.log(9)
        }
    }, [pleskFiles, pleskDomains, pleskRepositories, gitProjects, pleskDatabases, teamworkProjects, teamworkProjectsNoTag, teamworkProjectsInactive, teamworkTags])

    useEffect(() => {
        M.AutoInit()
    }, [projects])

    if (loading) {
        return (
            <StartPreloader stepGit={gitProjects}
                            stepPleskFiles={pleskFiles}
                            stepPleskRepositories={pleskRepositories}
                            stepPleskDomains={pleskDomains}
                            stepTeamworkProjects={teamworkProjects}
                            stepTeamworkTags={teamworkTags}
                            stepPleksDatabases={pleskDatabases}/>
        )
    }

    return (
        <>
            <ProjectsTabs projects={projects}/>
        </>
    )
}