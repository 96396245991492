const settings = {
    // Api Teamwork config
    "client_id": "74fb82605330e947a68e4a4242f06a77378a8925",
    "client_secret": "b9f3eba5b2f79da9f0470f7b18779cca2a42ead1",
    "redirect_uri": "https://wdscode.guru/",
    "teamwork_auth": "c29jY2VyMTYwcG9zdGVyOm15YXNrMTdmb3J0dXRv",
    "teamwork_uri": "https://iigglobal.teamwork.com",
    "main_folder": "public/",
    "trash_default_path": "public",
    "wiki_article": "https://wiki.webdesignsun.com/knowledge-base/plug",
    "folder_name": "wdscode.guru/",
    "site_name": "wdscode.guru",
    "summer_time": 60,
}

export default settings