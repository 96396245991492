import React from 'react'

export const Size = ({size}) => {
    return (
        (size === null) ? <li><strong>Size: 0B</strong></li> :
            <li className={size !== undefined && (size.indexOf('G') + 1) ? "large-size" : null}>
                <strong>Size:</strong> {(size === undefined) ? '0K' : size}
            </li>

    )
}