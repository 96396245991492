import React from 'react'
import moment from 'moment'
import {TeamworkProjectItem} from "./ProjectsItems";

export const PopupUserDetails = ({closePopup, userId, persons, startDate, endDate, onlineSet, stausesSet, voicesSet}) => {
    // const random = Math.random().toString(36).substring(7)

    console.log('------------------------------')
    console.log(onlineSet)
    console.log(stausesSet)
    console.log(voicesSet)
    console.log('------------------------------')

    // Get profile
    let person
    for(const p of persons)
        if(p.id === userId)
            person = p

    // Get start and end date(Year, month, date)
    const dateStart = moment(startDate)
    const dateEnd = moment(endDate)
    const yearStart = moment(startDate).year()
    const yearDiff = moment(endDate).year() - moment(startDate).year()
    const monthStart = moment(startDate).month()
    const monthDiff = moment(endDate).month() + (yearDiff * 12) - moment(startDate).month()

    // Init statistic data
    let statistic = []
    let i = 0
    let j = 0
    let monthCount = monthStart
    for(i; i <= yearDiff; i++) {
        statistic[yearStart + i] = []
        for(j; j <= monthDiff; j++) {
            statistic[yearStart + i][monthCount] = []
            if(monthCount === 11) {
                monthCount = 0
                break
            }
            monthCount++
        }
    }

    // Get Online time
    let online = 0
    for(const o of onlineSet)
        if(o.group === userId)
            online += moment(o.end_time).diff(moment(o.start_time), 'minutes')

    console.log(statistic)

    // Calculate
    let calculate = (set_value, calc_value) => {
        if(set_value === undefined)
            return calc_value

        return set_value[0] + calc_value
    }

    // Get Voice time
    for(const s of stausesSet)
        if(s.group === userId) {
            const timeStart = moment(s.start_time)
            const timeEnd = moment(s.end_time)
            const firstDayStart = moment(s.start_time).startOf('day')
            const firstDayEnd = moment(s.start_time).endOf('day')
            const lastDayStart = moment(s.end_time).startOf('day')
            const days = lastDayStart.diff(firstDayStart, 'days')
            const fullDayTime = 1440
            let date = moment(s.start_time) // set date

            if(days === 0) {
                statistic[date.year()][date.month()][date.date()] = [
                    calculate(statistic[date.year()][date.month()][date.date()], timeEnd.diff(timeStart, 'minutes')),
                    timeStart.day()%6==0
                ]
            } else {
                if(days === 1) {
                    statistic[date.year()][date.month()][date.date()] = [
                        calculate(statistic[date.year()][date.month()][date.date()], lastDayStart.diff(timeStart, 'minutes')),
                        date.day()%6==0
                    ]
                    date.add(1,'day')
                    statistic[date.year()][date.month()][date.date()] = [
                        calculate(statistic[date.year()][date.month()][date.date()], timeEnd.diff(lastDayStart, 'minutes')),
                        date.day()%6==0
                    ]
                } else {
                    const firstDayPeriod = firstDayEnd.diff(timeStart, 'minutes') + 1
                    const lastDayPeriod = timeEnd.diff(lastDayStart, 'minutes')
                    statistic[date.year()][date.month()][date.date()] = [
                        calculate(statistic[date.year()][date.month()][date.date()], firstDayPeriod),
                        date.day()%6==0
                    ]

                    let i = 1
                    for(i; i < days; i++) {
                        date.add(1, 'day')
                        statistic[date.year()][date.month()][date.date()] = [
                            calculate(statistic[date.year()][date.month()][date.date()], fullDayTime),
                            date.day()%6==0
                        ]
                    }

                    date.add(1, 'day')
                    statistic[date.year()][date.month()][date.date()] = [
                        calculate(statistic[date.year()][date.month()][date.date()], lastDayPeriod),
                        date.day()%6==0
                    ]
                }
            }
        }

    console.log('statistic')
    console.log(statistic)

    // Get Some data time
    let voices = 0
    for(const v of voicesSet)
        if(v.group === userId)
            voices += moment(v.end_time).diff(moment(v.start_time), 'minutes')

    return (
        <div className="modal-user-detailed modal modal-fixed-footer">
            <div className="modal-content">
                <div className="person-head">
                    <div className="person-avatar">
                        <img className="work_img" src={person.image} alt={person.name}/>
                    </div>
                    <div className="person-head-info">
                        <h5>{person.name} ({person.title})</h5>
                        <p className="status-show st-online">{parseInt(online / 60)}:{(online % 60)}</p>
                        <p className="status-show">
                            <img src={process.env.PUBLIC_URL + '/assets/img/statuses/headphones.png'}
                                 alt="voice"/>{parseInt(voices / 60)}:{(voices % 60)}</p>
                        <p className="time-period">Period: <strong>
                            {dateStart.format('DD/MM/YYYY')} - {dateEnd.format('DD/MM/YYYY')}
                            </strong>
                        </p>
                    </div>
                </div>
                <div className="days-calendar">
                    {statistic.map((obj, key) => {
                        return (
                            <div key={key}>
                                <h5>Year: {key}</h5>
                                {
                                    obj.map((sub, subindex) => {
                                        return (
                                            <div key={subindex}>
                                                <h4>{subindex}</h4>
                                                {
                                                    sub.map((subs, subindexs) =>
                                                        <div key={subindexs}>{subindexs}</div>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="modal-footer">
                <a href="#" className="modal-close waves-effect waves-green btn-flat" onClick={() => closePopup(null)}>Close</a>
            </div>
        </div>
    )
}