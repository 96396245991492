import React from 'react'

export const FormateDate = ({setDate}) => {
    function appendLeadingZeroes(n){
        if(n <= 9){
            return "0" + n;
        }
        return n
    }

    const newDate = (date) => {
        let current_datetime = new Date(date)
        return (current_datetime.getFullYear() + "-" + appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" + appendLeadingZeroes(current_datetime.getDate()))
    }

    return newDate(setDate)
}