import React, {useCallback, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {Loader} from '../components/Loader'
import {PopupUserDetails} from '../components/PopupUserDetails'
import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader
} from "react-calendar-timeline/lib"
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import M from 'materialize-css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {statuses_ico} from "../config/statuses"
import config from '../config/config'

export const SlackTimelinePage = () => {
    const {request} = useHttp()
    const [loading, setLoading] = useState(true)
    const [persons, setPersons] = useState([]) // Groups for timeline
    const [personsSave, setPersonsSave] = useState([]) // Copy of all groups of persons
    const [activities, setActivities] = useState([])  // All activities for timeline
    const [onlineSet, setOnlineSet] = useState([])  // Online for timeline
    const [voicesSet, setVoicesSet] = useState([])  // Voive for timeline
    const [statusesSet, setStatusesSet] = useState([])  // Status for timeline
    // Search switchers
    const [onlineFlag, setOnlineFlag] = useState(true)
    const [voiceFlag, setVoiceFlag] = useState(true)
    const [statusFlag, setStatusFlag] = useState(true)
    const [statusClass, setStatusClass] = useState('onvost')
    const [sortFlag, setSortFlag] = useState('name_a')
    const [searchPersons, setSearchPersons] = useState( JSON.parse(localStorage.getItem('setPersons')) ? JSON.parse(localStorage.getItem('setPersons')) : [] )
    const timeOffset = 50 - config.summer_time // Time offset
    // View
    const [sideWidth, setSideWidth] = useState(250)
    // Datepicker
    const [dateRange, setDateRange] = useState([moment().startOf('day').toDate(),
        moment().endOf('day').toDate()]);
    const [startDate, endDate] = dateRange;
    // Bears
    const [ourBears, setOurBears] = useState([])
    // Unemployed right now
    const [ourUnemployed, setOurUnemployed] = useState([])
    // Show user detailed popup
    const [detailedPopupFlag, setDetailedPopupFlag] = useState(false)

    // ClosePopup
    const closeDetailedPopup = useCallback((value) => {
        setDetailedPopupFlag(value)
    })

    const addItem = (count, index, title, start_time, end_time, class_name, status = false) => {
        const time = (end_time - start_time) / 60000;
        const hours = parseInt(time / 60);
        const minutes = time % 60;

        return {
            id: count,
            group: index,
            start_time: start_time,
            end_time: end_time,
            canMove: false,
            canResize: false,
            status: (class_name === 'status') ? (statuses_ico[status]) ? statuses_ico[status] : statuses_ico['default'] : false,
            custom_title: title + ' (' + hours + ":" + minutes + ")",
            itemProps: {
                className: class_name,
            }
        }
    }

    const isUserExists = (user_mixing, user_id) => {
        return (user_mixing[user_id] === undefined)
    }

    const generateStatusesDate = async (statuses) => {
        // Help values
        let temp_activities = [] // for online
        let temp_voices = [] // for voices
        let temp_statuses = [] // for statuses
        let temp_text = [] // for statuses
        let temp_date = [] // for statuses
        let count = 1
        // Items value
        let data_online = []
        let data_voices = []
        let data_statuses = []
        // Total time calculates
        let all_online_time = []
        let all_voices_time = []
        // Generate groups for timeline
        let setNames = statuses.statistics[statuses.statistics.length - 1]['data'] // Check if new user is add
        // Remember users ids with mixing if user is deleted for old statistics
        let users_mixing = []
        let index_mixing = 0
        for(let i = 0; i < setNames.length; i++) {
            if (statuses.users[setNames[i].i] === undefined) {
                index_mixing++
            } else {
                users_mixing[setNames[i].i] = i - index_mixing
            }
        }

        statuses.statistics.forEach(function (item) {
            for (const [index, value] of item['data'].entries()) {
                // skip if user deleted
                if (isUserExists(users_mixing, value.i))
                    continue;

                // new mixing index
                let new_index = users_mixing[value.i]

                // Online check
                if (value.s === 'active') {
                    if (!temp_activities[new_index])
                        temp_activities[new_index] = item['date']
                } else {
                    if (temp_activities[new_index]) {
                        // calculate online time
                        let online_from = moment(temp_activities[new_index]).add(timeOffset, 'm').toDate()
                        let online_to = moment(item['date']).add(timeOffset, 'm').toDate()
                        if(all_online_time[new_index]) {
                            all_online_time[new_index] += (online_to - online_from)
                        } else {
                            all_online_time[new_index] = (online_to - online_from)
                        }
                        // Add period
                        data_online.push(addItem(count,
                            new_index,
                            moment(online_from).format('HH:mm') + ' - ' + moment(online_to).format('HH:mm'),
                            online_from,
                            online_to,
                            'online',
                            ))
                        count++
                        temp_activities[new_index] = false
                    }
                }

                // Voice check
                if(value.h === 'in_a_huddle') {
                    if(!temp_voices[new_index])
                        temp_voices[new_index] = item['date']
                } else {
                    if(temp_voices[new_index]) {
                        // calculate voice time
                        let voice_from = moment(temp_voices[new_index]).add(timeOffset, 'm').toDate()
                        let voice_to = moment(item['date']).add(timeOffset, 'm').toDate()
                        if(all_voices_time[new_index]) {
                            all_voices_time[new_index] += (voice_to - voice_from)
                        } else {
                            all_voices_time[new_index] = (voice_to - voice_from)
                        }

                        // Add period
                        data_voices.push(addItem(count,
                            new_index,
                            moment(voice_from).format('HH:mm') + ' - ' + moment(voice_to).format('HH:mm'),
                            voice_from,
                            voice_to,
                            'voice'))
                        count++
                        temp_voices[new_index] = false
                    }
                }

                // Statuses check
                let status_from = moment(temp_date[new_index]).add(timeOffset, 'm').toDate()
                let status_to = moment(item['date']).add(timeOffset, 'm').toDate()
                if(value.e) {
                    if(temp_statuses[new_index] !== value.e && temp_date[new_index]) {
                        data_statuses.push(addItem(count,
                            new_index,
                            temp_text[new_index]  + ' - (' +  moment(status_from).format('HH:mm') + ' - ' + moment(status_to).format('HH:mm') + ')',
                            status_from,
                            status_to,
                            'status',
                            temp_statuses[new_index]))
                        count++

                        temp_date[new_index] = item['date']
                    } else if(temp_statuses[new_index] !== value.e){
                        temp_date[new_index] = item['date']
                    }
                } else {
                    if(temp_date[new_index]) {
                        data_statuses.push(addItem(count,
                            new_index,
                            temp_text[new_index]  + ' - (' +  moment(status_from).format('HH:mm') + ' - ' + moment(status_to).format('HH:mm') + ')',
                            status_from,
                            status_to,
                            'status',
                            temp_statuses[new_index]))
                        count++
                    }
                    temp_date[new_index] = false
                }
                temp_statuses[new_index] = value.e
                temp_text[new_index] = value.t
            }
        })

        // Close and Add latest statistics
        let current_date = moment(statuses.statistics[(statuses.statistics.length - 1)]['date']).add(timeOffset + 10, 'm').toDate()

        // Online
        for (const [index, value] of temp_activities.entries()) {
            if (value) {
                if(all_online_time[index]) {
                    all_online_time[index] += (moment(current_date).toDate() - moment(value).add(timeOffset, 'm').toDate())
                } else {
                    all_online_time[index] = (moment(current_date).toDate() - moment(value).add(timeOffset, 'm').toDate())
                }
                data_online.push(addItem(count,
                    index,
                    moment(value).add(timeOffset, 'm').format('HH:mm') + ' - ' + moment(current_date).format('HH:mm'),
                    moment(value).add(timeOffset, 'm').toDate(),
                    current_date,
                    'online'))
                count++
            }
        }
        // Voice
        for (const [index, value] of temp_voices.entries()) {
            if(value) {
                if(all_voices_time[index]) {
                    all_voices_time[index] += (moment(current_date).toDate() - moment(value).add(timeOffset, 'm').toDate())
                } else {
                    all_voices_time[index] = (moment(current_date).toDate() - moment(value).add(timeOffset, 'm').toDate())
                }

                data_voices.push(addItem(count,
                    index,
                    moment(value).add(timeOffset, 'm').format('HH:mm') + ' - ' + moment(current_date).format('HH:mm'),
                    moment(value).add(timeOffset, 'm').toDate(),
                    current_date,
                    'voice'))
                count++
            }
        }
        // Status
        for (const [index, value] of temp_date.entries()) {
            if(value) {
                data_statuses.push(addItem(count,
                    index,
                    temp_text[index]  + ' - (' +  moment(value).add(timeOffset, 'm').format('HH:mm') + ' - ' + moment(current_date).format('HH:mm') + ')',
                    moment(value).add(timeOffset, 'm').toDate(),
                    current_date,
                    'status',
                    temp_statuses[index]))
                count++
            }
        }

        let data_persons = []
        let bears = [] // list of users without title
        let unemployed = [] // list of users without work
        for(let i = 0; i < setNames.length; i++) {
            // check if user active
            if(statuses.users[setNames[i].i] !== undefined) {
                data_persons[users_mixing[setNames[i].i]] = {
                    id: users_mixing[setNames[i].i],
                    name: statuses.users[setNames[i].i].real_name,
                    title: statuses.users[setNames[i].i].title,
                    image: statuses.users[setNames[i].i].image,
                    height: (13.5 * statusClass.length),
                    online: all_online_time[users_mixing[setNames[i].i]] ? all_online_time[users_mixing[setNames[i].i]] : 0,
                    voices: all_voices_time[users_mixing[setNames[i].i]] ? all_voices_time[users_mixing[setNames[i].i]] : 0
                }

                // check if user has title
                if (!statuses.users[setNames[i].i].title)
                    bears.push(statuses.users[setNames[i].i].image)

                // Get our unemployed
                if (setNames[i].e === ':free:')
                    unemployed.push(statuses.users[setNames[i].i].image)
            }
        }

        // set bears ;), at first upload
        if(ourBears.length === 0)
            setOurBears(JSON.parse(JSON.stringify(bears)))
        // set our unemployed at first upload
        if(ourUnemployed.length === 0)
            setOurUnemployed(JSON.parse(JSON.stringify(unemployed)))

        // Set all data and let it go
        /*if(personsSave.length === 0) {
            setSearchPersons(data_persons.map(({ id }) => id.toString()))
            setPersons(JSON.parse(JSON.stringify(data_persons)))
            setPersonsSave(JSON.parse(JSON.stringify(data_persons)))
        }*/


        setPersons(JSON.parse(JSON.stringify(sortPersons(data_persons))))
        setPersonsSave(JSON.parse(JSON.stringify(data_persons)))

        if(searchPersons.length === 0)
            setSearchPersons(data_persons.map(({ id }) => id.toString()))

        setActivities(data_online.concat(data_voices, data_statuses))
        setOnlineSet(data_online)
        setVoicesSet(data_voices)
        setStatusesSet(data_statuses)
        setLoading(false)
    }

    const getStatuses = async (from = null, to = null) => {
        try {
            let url = (from && to) ? `/api/server/statistics?from=${from}&to=${to}` : '/api/server/statistics'
            const fetched = await request(url, 'GET')
            await generateStatusesDate(fetched)
        } catch (e) {
        }
    }

    useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
            const startDay = moment(dateRange[0]).startOf('day').add((-59 - config.summer_time),'m').format()
            const endDay = moment(dateRange[1]).endOf('day').add((-59 - config.summer_time),'m').format()
            getStatuses(startDay, endDay)
        }
    }, [dateRange])

    useEffect(() => {
        if(!loading) {
            M.AutoInit()
        }
    }, [loading])

    // Timeline configs
    const groupRenderer = ({ group }) => {
        const online = parseInt((group.online / 3600000)) + ':' + parseInt((group.online % 3600000) / 60000)
        const voices = parseInt((group.voices / 3600000)) + ':' + parseInt((group.voices % 3600000) / 60000)

        return (
            <div className="person-info">
                <img className="avatar" src={group.image} alt={group.title}/>
                <span className="detailed_info" onClick={() => setDetailedPopupFlag(group.id)}>
                    <img src={process.env.PUBLIC_URL + '/assets/img/info.svg'} alt="more info"/>
                </span>
                <p className="name">
                    {group.name}
                </p>
                {(group.title) ? <p className="title">{group.title}</p> : <p className="title">---- ʕ ᵔᴥᵔ ʔ ----</p>}
                <p className="online_value">
                    {online}
                </p>
                <p className="voice_value">
                    <img src={process.env.PUBLIC_URL + '/assets/img/statuses/headphones.png'}
                         alt="voice"/>{voices}
                </p>
            </div>
        )
    }

    const itemRenderer = ({item, itemContext, getItemProps, getResizeProps}) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
        const status = <img className="status_ico" src={process.env.PUBLIC_URL + '/assets/img/statuses/' + item.status} alt="status"/>
        const status_template = <>
            <img className="status_ico" src={process.env.PUBLIC_URL + '/assets/img/statuses/' + item.status}
                 alt="status"/> <span>{item.custom_title}</span>
        </>

        return (
            <div {...getItemProps(item.itemProps)}>
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

                <div className={'rct-item-content'} style={{ maxHeight: `${itemContext.dimensions.height}` }}>
                    <div className="tooltip">
                        {item.itemProps.className === 'online' ? item.custom_title : null}
                        {item.itemProps.className === 'voice' ? item.custom_title : null}
                        {item.itemProps.className === 'status' ? status_template : null}
                    </div>
                    {item.itemProps.className === 'status' ? status : null}
                </div>

                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
            </div>
        )
    }

    // Change show flags
    useEffect(() => {
        let newSet = []
        let classSet = ''

        if(onlineFlag) {
            newSet = newSet.concat(onlineSet)
            classSet += 'on'
        }
        if(voiceFlag) {
            newSet = newSet.concat(voicesSet)
            classSet += 'vo'
        }
        if(statusFlag) {
            newSet = newSet.concat(statusesSet)
            classSet += 'st'
        }
        (classSet.length <= 2) ? setSideWidth(350) : setSideWidth (250)

        setActivities(newSet)
        setStatusClass(classSet)
        // Change height
        let newPerson = JSON.parse(JSON.stringify(persons))
        for(let i = 0;i < newPerson.length; i++)
            newPerson[i].height = (13.5 * classSet.length)
        setPersons(newPerson)

    }, [onlineFlag, voiceFlag, statusFlag])

    // Change sort flag
    useEffect(() => {
        setPersons(sortPersons(JSON.parse(JSON.stringify(persons))))
    }, [sortFlag])

    // Sort person Helper function
    const sortPersons = (list) => {
        switch (sortFlag) {
            case "online_a":
                list.sort((a, b) => {
                    return a.online - b.online
                })
                break
            case "online_d":
                list.sort((a, b) => {
                    return b.online - a.online
                })
                break
            case "voice_a":
                list.sort((a, b) => {
                    return a.voices - b.voices
                })
                break
            case "voice_d":
                list.sort((a, b) => {
                    return b.voices - a.voices
                })
                break
            case "name_a":
                list.sort((a, b) => a.name.localeCompare(b.name))
                break
            default:
                list.sort((a, b) => b.name.localeCompare(a.name))
        }

        return list
    }

    // Change persons group
    useEffect(() => {
        let newPersons = []
        for (const [index, value] of personsSave.entries())
            if (searchPersons.includes(value.id.toString()))
                newPersons.push(value)

        // Change and sort if changes
        if(newPersons.length !== persons.length) {
            for(let i = 0;i < newPersons.length; i++)
                newPersons[i].height = (13.5 * statusClass.length)
            setPersons(sortPersons(newPersons))
        }
        // Save onli after loading
        if (!loading) {
            localStorage.setItem('setPersons', JSON.stringify(searchPersons));
        }
    }, [searchPersons, personsSave])

    if (loading) {
        return <Loader/>
    }

    /* Render helper */
    // Helper for Search components
    const listPersonItems = personsSave.map((person) =>
        <option key={person.id} value={person.id} data-icon={person.image}>{person.name}</option>
    )

    // Bears
    const listBears = ourBears.map((value, i) =>
        <img key={i} src={value} alt="person" />
    )
    // Unemployed
    const listUnemployed = ourUnemployed.map((value, i) =>
        <img key={i} src={value} alt="person" />
    )

    return (
        <>
            {(detailedPopupFlag) ? <PopupUserDetails
                closePopup={closeDetailedPopup}
                userId={detailedPopupFlag}
                persons={persons}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                stausesSet={statusesSet}
                onlineSet={onlineSet}
                voicesSet={voicesSet}
            /> : null}
            <div className="row">
                <div className="col s3">
                    <h1>Activities</h1>
                </div>
                {(listBears)
                    ? <div className="col s3 bears">
                        <h5>our ʕ ᵔᴥᵔ ʔ:</h5>
                        <div className="list">
                            {listBears}
                        </div>
                    </div>
                    : null}
                {(listUnemployed)
                    ? <div className="col s6 unemployed">
                        <img className="work_img" src={process.env.PUBLIC_URL + '/assets/img/statuses/free.png'} alt="without work"/>
                        <div className="list">
                            {listUnemployed}
                        </div>
                    </div>
                    : null}
            </div>
            <div className="row">
                <div className="input-field col s3">
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        isClearable={true}
                    />
                    <label className="custom-label">Date range:</label>
                </div>
                <div className="input-field col s1">
                    <div className="switch">
                        <label>
                            Online:
                            <input type="checkbox" defaultChecked={true} onClick={() => setOnlineFlag(!onlineFlag)} value={onlineFlag} />
                            <span className="lever" />
                        </label>
                    </div>
                </div>
                <div className="input-field col s1">
                    <div className="switch">
                        <label>
                            Statuses:
                            <input type="checkbox" defaultChecked={true} onClick={() => setStatusFlag(!statusFlag)} value={statusFlag} />
                            <span className="lever" />
                        </label>
                    </div>
                </div>
                <div className="input-field col s1">
                    <div className="switch">
                        <label>
                            Voices:
                            <input type="checkbox" defaultChecked={true} onClick={() => setVoiceFlag(!voiceFlag)} value={voiceFlag} />
                            <span className="lever" />
                        </label>
                    </div>
                </div>
                <div className="input-field col s2">
                    <select className={'sss'} onChange={(e) => setSortFlag(e.target.value)}>
                        <optgroup label="Name">
                            <option value="name_a" data-icon={process.env.PUBLIC_URL + '/assets/img/sort_down.svg'} defaultValue>Name ASC</option>
                            <option value="name_d" data-icon={process.env.PUBLIC_URL + '/assets/img/sort_up.svg'}>Name DESC</option>
                        </optgroup>
                        <optgroup label="Online">
                            <option value="online_a" data-icon={process.env.PUBLIC_URL + '/assets/img/sort_down.svg'}>Online ASC</option>
                            <option value="online_d" data-icon={process.env.PUBLIC_URL + '/assets/img/sort_up.svg'}>Online DESC</option>
                        </optgroup>
                        <optgroup label="Voice">
                            <option value="voice_a" data-icon={process.env.PUBLIC_URL + '/assets/img/sort_down.svg'}>Voice ASC</option>
                            <option value="voice_d" data-icon={process.env.PUBLIC_URL + '/assets/img/sort_up.svg'}>Voice DESC</option>
                        </optgroup>
                    </select>
                    <label>Sort by:</label>
                </div>
                <div className="input-field col people-select s4">
                    <select multiple defaultValue={searchPersons} onChange={(e) => setSearchPersons(Array.from(e.target.selectedOptions, option => option.value))}>
                        {listPersonItems}
                    </select>
                    <label>Show by:</label>
                </div>
            </div>
            <Timeline
                groups={persons}
                stackItems
                items={activities}
                defaultTimeStart={moment().startOf('day').toDate()}
                defaultTimeEnd={moment().endOf('day').toDate()}
                timeSteps={{minute: 10, hour: 1, day: 1, month: 1, year: 1}}
                groupRenderer={groupRenderer}
                itemRenderer={itemRenderer}
                sidebarWidth={sideWidth}
                className={statusClass}
            >
                <TimelineHeaders className="sticky">
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            return <div className={'history'} {...getRootProps()}>
                                <div className={'ico_online'} />
                                <span>Online</span>
                                <div className={'ico_status'} />
                                <span>Statuse</span>
                                <div className={'ico_voice'} />
                                <span>Huddle</span>
                            </div>
                        }}
                    </SidebarHeader>
                    <DateHeader unit="primaryHeader" />
                    <DateHeader />
                </TimelineHeaders>
            </Timeline>
        </>
    )
}