import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {AuthContext} from '../context/AuthContext'

export const Navbar = () => {
    const history = useHistory()
    const auth = useContext(AuthContext)

    const logoutHandler = event => {
        event.preventDefault()
        auth.logout()
        history.push('/')
    }

    return (
        <>
            <ul id="dropdown1" className="dropdown-content">
                <li><a href="/dashboard">Dashboard</a></li>
                <li><a href="/activities">Activities</a></li>
                <li className="divider"></li>
                <li><a href="/" onClick={logoutHandler}>Logout</a></li>
            </ul>
            <nav className="navigation">
                <div className="nav-wrapper">
                    <img className="left logo" src={process.env.PUBLIC_URL + 'logo.png'} alt="avatar"/>
                    <ul className="right auth-user">
                        <li><img src={auth.user.avatar} alt="avatar"/></li>
                        <li><a className="dropdown-trigger" href="#!" data-target="dropdown1">
                                {auth.user.firstName} {auth.user.lastName}
                                <i className="fas fa-solid fa-caret-down"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}
