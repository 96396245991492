import React from 'react'

export const Search = ({getSearch, search, placeholder = 'Search'}) => {
    const random = Math.random().toString(36).substring(7)

    return (
        <div className="row">
            <div class="input-field col s12">
                <i class="fas fa-search prefix"></i>
                <input id={random} type="text" class="validate" onChange={e => getSearch(e.target.value)}
                       value={search}/>
                <label for={random}>{placeholder}</label>
            </div>
        </div>
    )
}