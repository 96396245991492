import React from 'react'
import config from '../config/config'

export const AuthPage = () => {
    const loginHandler = () => {
        //Todo maybe need to change from backend
        window.location.href = 'https://www.teamwork.com/launchpad/login?redirect_uri=' + config.redirect_uri +
            '&client_id=' + config.client_id
    }

    return (
        <div className="row">
            <div className="col s12 center-align login-form">
                <img src={process.env.PUBLIC_URL + 'logo.png'} alt="avatar 1111"/>
                <button className="waves-effect waves-light btn-large" onClick={loginHandler}>
                    Start
                </button>
            </div>
        </div>
    )
}
