import React from 'react'
import {
    ProjectList, TrashList, EmptyProjectList, ErrorGitProjectList, FtpProjectList, PausedProjectList,
    NoUserDatabaseList, NoUsedDatabaseList, TeamworkProjectList
} from './ProjectsList'
import {Link} from './Link'
import config from '../config/config'
import {isMobile} from "react-device-detect"

export const ProjectsTabs = ({projects}) => {
    const size_folder = Object.keys(projects['folders']).length,
        size_ftp = Object.keys(projects['ftp']).length,
        size_unused_git = Object.keys(projects['unused_git']).length,
        size_privacy = Object.keys(projects['privacy']['gits']).length +
            Object.keys(projects['privacy']['projects']).length,
        size_tw_no_relation = Object.keys(projects['tw_no_relation']['projects']).length +
            Object.keys(projects['tw_no_relation']['ftps']).length +
            Object.keys(projects['tw_no_relation']['teamwork']).length,
        size_error_git = Object.keys(projects['error_git']).length,
        size_empty = Object.keys(projects['empty']).length,
        size_unused_db = Object.keys(projects['unused_db']).length,
        size_no_user_db = Object.keys(projects['no_user_db']).length,
        size_inactive_projects = Object.keys(projects['inactive_projects']['projects']).length +
            Object.keys(projects['inactive_projects']['ftps']).length +
            Object.keys(projects['inactive_projects']['gits']).length,
        size_tw_inactive = Object.keys(projects['tw_inactive']).length,
        size_trash = Object.keys(projects['trash']).length

    return (
        <>
            <div className="row">
                <div className="col s12">
                    <ul className={(isMobile) ? "tabs mobile" : "tabs"}>
                        {(size_folder) ?
                            <li className={"tab col valid"}>
                                <a className="waves-effect waves-light active white-text" href="#tab1">Active <span
                                    class="badge-num">{size_folder}</span></a>
                            </li> : null
                        }
                        {(size_ftp) ?
                            <li className={"tab col questionable"}>
                                <a className="waves-effect waves-light white-text" href="#tab2">FTP <span
                                    class="badge-num">{size_ftp}</span></a>
                            </li> : null
                        }
                        {(size_unused_git) ?
                            <li className={"tab col questionable"}>
                                <a className="waves-effect waves-light white-text" href="#tab3">Hidden <span
                                    class="badge-num">{size_unused_git}</span></a>
                            </li> : null
                        }
                        {(size_error_git) ?
                            <li className={"tab col questionable"}>
                                <a className="waves-effect waves-light white-text" href="#tab4">Repository <span
                                    class="badge-num">{size_error_git}</span></a>
                            </li> : null
                        }
                        {(size_privacy) ?
                            <li className={"tab col error"}>
                                <a className="waves-effect waves-light white-text" href="#tab5">Privacy <span
                                    class="badge-num">{size_privacy}</span></a>
                            </li> : null
                        }
                        {(size_tw_no_relation) ?
                            <li className={"tab col error"}>
                                <a className="waves-effect waves-light white-text" href="#tab6">TW relation <span
                                    class="badge-num">{size_tw_no_relation}</span></a>
                            </li> : null
                        }
                        {(size_empty) ?
                            <li className={"tab col error"}>
                                <a className="waves-effect waves-light white-text" href="#tab7">Empty <span
                                    class="badge-num">{size_empty}</span></a>
                            </li> : null
                        }
                        {(size_unused_db) ?
                            <li className={"tab col error"}>
                                <a className="waves-effect waves-light white-text" href="#tab8">Db Relation <span
                                    class="badge-num">{size_unused_db}</span></a>
                            </li> : null
                        }
                        {(size_no_user_db) ?
                            <li className={"tab col error"}>
                                <a className="waves-effect waves-light white-text" href="#tab9">Db Witout user <span
                                    class="badge-num">{size_no_user_db}</span></a>
                            </li> : null
                        }
                        {(size_inactive_projects) ?
                            <li className={"tab col error"}>
                                <a className="waves-effect waves-light white-text" href="#tab10">Inactive projects <span
                                    class="badge-num">{size_inactive_projects}</span></a>
                            </li> : null
                        }
                        {(size_tw_inactive) ?
                            <li className={"tab col error"}>
                                <a className="waves-effect waves-light white-text" href="#tab11">TW inactive <span
                                    class="badge-num">{size_tw_inactive}</span></a>
                            </li> : null
                        }
                        {(size_trash) ?
                            <li className={"tab col error"}>
                                <a className="waves-effect waves-light white-text" href="#tab12">Trash <span
                                    class="badge-num">{size_trash}</span></a>
                            </li> : null
                        }
                    </ul>
                </div>
                {(size_folder) ?
                    <div id="tab1" className="col s12">
                        <h5>Projects <Link url={config.wiki_article + '#active'}/></h5>
                        <i>Information about <strong>sizes</strong>, <strong>trash</strong> and <strong>
                            databases</strong> is updated every <strong>4 hours</strong>.</i>
                        <ProjectList projects={projects['folders']}/>
                    </div> : null
                }
                {(size_ftp) ?
                    <div id="tab2" className="col s12">
                        <h5>Ftp Projects <Link url={config.wiki_article + '#ftp'}/></h5>
                        <p><i class="fas fa-info"></i>Projects without Git.</p>
                        <FtpProjectList projects={projects['ftp']}/>
                    </div> : null
                }
                {(size_unused_git) ?
                    <div id="tab3" className="col s12">
                        <h5>Gits <Link url={config.wiki_article + '#hidden'}/></h5>
                        <p><i class="fas fa-info"></i>Unused Git Projects.</p>
                        <PausedProjectList projects={projects['unused_git']}/>
                    </div> : null
                }
                {(size_error_git) ?
                    <div id="tab4" className="col s12">
                        <h5>Gits <Link url={config.wiki_article + '#repository'}/></h5>
                        <p><i class="fas fa-info"></i>Projects with repositories on the server that are not on
                            our Git.</p>
                        <ErrorGitProjectList projects={projects['error_git']}/>
                    </div> : null
                }
                {(size_privacy) ?
                    <div id="tab5" className="col s12">
                        <h5>Projects <Link url={config.wiki_article + '#privacy'}/></h5>
                        <p><i class="fas fa-info"></i>Public Git Projects.</p><i>All git projects must be Private.</i>
                        <ProjectList projects={projects['privacy']['projects']}/>
                        <h5>Gits <Link url={config.wiki_article + '#privacy'}/></h5>
                        <p><i class="fas fa-info"></i>Public Git Projects.</p><i>All git projects must be Private.</i>
                        <PausedProjectList projects={projects['privacy']['gits']}/>
                    </div> : null
                }
                {(size_tw_no_relation) ?
                    <div id="tab6" className="col s12">
                        <h5>Projects <Link url={config.wiki_article + '#tw_relation'}/></h5>
                        <p><i class="fas fa-info"></i>Projects without reference to Teamwork.</p><i>To link a project,
                        you need to go to the current project in the teamwork and add the
                        project <strong>domain</strong> to <strong>Tags</strong>. <strong>norelative</strong> -
                        this tag should be used for all projects that are <strong>no intended</strong> for developing projects on our
                        server.</i>
                        <ProjectList projects={projects['tw_no_relation']['projects']}/>
                        <h5>Ftp Projects <Link url={config.wiki_article + '#tw_relation'}/></h5>
                        <p><i class="fas fa-info"></i>Ftp Projects without reference to Teamwork.</p><i>To link a
                        project, you need to go to the current project in the teamwork and add the
                        project <strong>domain</strong> to <strong>Tags</strong>. <strong>norelative</strong> -
                        this tag should be used for all projects that are <strong>no intended</strong> for developing projects on our
                        server.</i>
                        <FtpProjectList projects={projects['tw_no_relation']['ftps']}/>
                        <h5>Teamwork Projects <Link url={config.wiki_article + '#tw_relation'}/></h5>
                        <p><i className="fas fa-info"></i>Teamwork Projects without reference to Domains.</p><i>To link
                        a project, you need to go to the current project in the teamwork and add the
                        project <strong>domain</strong> to <strong>Tags</strong>. <strong>norelative</strong> -
                        this tag should be used for all projects that are <strong>no intended</strong> for developing projects on our
                        server.</i>
                        <TeamworkProjectList projects={projects['tw_no_relation']['teamwork']}/>
                    </div> : null
                }
                {(size_empty) ?
                    <div id="tab7" className="col s12">
                        <h5>Domains <Link url={config.wiki_article + '#empty'}/></h5>
                        <p><i class="fas fa-info"></i>Domains without any files on server.</p>
                        <EmptyProjectList projects={projects['empty']}/>
                    </div> : null
                }
                {(size_unused_db) ?
                    <div id="tab8" className="col s12">
                        <h5>Databases <Link url={config.wiki_article + '#db_relation'}/></h5>
                        <p><i class="fas fa-info"></i>Domainless databases.</p>
                        <i>This section is updated every <strong>4 hours</strong>.</i>
                        <NoUsedDatabaseList databases={projects['unused_db']}/>
                    </div> : null
                }
                {(size_no_user_db) ?
                    <div id="tab9" className="col s12">
                        <h5>Databases <Link url={config.wiki_article + '#db_witout_user'}/></h5>
                        <p><i class="fas fa-info"></i>Databases without a user.</p>
                        <i>This section is updated every <strong>4 hours</strong>.</i>
                        <NoUserDatabaseList databases={projects['no_user_db']}/>
                    </div> : null
                }
                {(size_inactive_projects) ?
                    <div id="tab10" className="col s12">
                        <h5>Projects <Link url={config.wiki_article + '#inactive_projects'}/></h5>
                        <p><i class="fas fa-info"></i>Projects without activity for more than <strong>2 months</strong>.</p>
                        <ProjectList projects={projects['inactive_projects']['projects']}/>
                        <h5>Ftp <Link url={config.wiki_article + '#inactive_projects'}/></h5>
                        <p><i class="fas fa-info"></i>Ftp Projects without activity for more than <strong>2 months</strong>.</p>
                        <FtpProjectList projects={projects['inactive_projects']['ftps']}/>
                        <h5>Gits <Link url={config.wiki_article + '#inactive_projects'}/></h5>
                        <p><i class="fas fa-info"></i>Gits Projects without activity for more than <strong>6 months</strong>.</p>
                        <PausedProjectList projects={projects['inactive_projects']['gits']}/>
                    </div> : null
                }
                {(size_tw_inactive) ?
                    <div id="tab11" className="col s12">
                        <h5>Teamwork projects <Link url={config.wiki_article + '#tw_inactive'}/></h5>
                        <p><i class="fas fa-info"></i>Projects without activity for more than <strong>2 months</strong>.</p>
                        <TeamworkProjectList projects={projects['tw_inactive']}/>
                    </div> : null
                }
                {(size_trash) ?
                    <div id="tab12" className="col s12">
                        <h5>Trash <Link url={config.wiki_article + '#trash'}/></h5>
                        <i>This section is updated every <strong>4 hours</strong>.</i>
                        <TrashList projects={projects['trash']}/>
                    </div> : null
                }
            </div>
        </>
    )
}
