import React, {useState, useCallback} from 'react'
import {ProjectItem, FtpProjectItem, ErrorGitProjectItem, TeamworkProjectItem} from './ProjectsItems'
import {Search} from './Search'
import {Size} from './Size'
import {FormateDate} from "./FormateDate";

export const ProjectList = ({projects}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Domain, Git and Teamwork name'}/>
            <div className="row">
                {Object.entries(projects).map(([key, obj], i) => (
                    <ProjectItem project={obj} key={key} search={search}/>
                ))}
            </div>
        </>
    )
}

export const FtpProjectList = ({projects}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Domain and Teamwork name'}/>
            <div className="row">
                {Object.entries(projects).map(([key, obj], i) => (
                    <FtpProjectItem project={obj} key={key} search={search}/>
                ))}
            </div>
        </>
    )
}

export const ErrorGitProjectList = ({projects}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Domain and Teamwork name'}/>
            <div className="row">
                {Object.entries(projects).map(([key, obj], i) => (
                    <ErrorGitProjectItem project={obj} key={key} search={search}/>
                ))}
            </div>
        </>
    )
}

export const TeamworkProjectList = ({projects}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Teamwork name'}/>
            <div className="row">
                {projects.map((obj, key) => {
                    return (
                        <TeamworkProjectItem project={obj} key={key} search={search}/>
                    )
                })}
            </div>
        </>
    )
}

export const PausedProjectList = ({projects}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Git name'}/>
            <div className="row">
                {Object.entries(projects).map(([key, obj], i) => (
                    (!search || obj.name.toLowerCase().indexOf(search.toLowerCase()) + 1) ?
                        <div className="col s12 m6 l4 card-project" key={key}>
                            <div className="card">
                                <div className="card-content">
                                    <h5>Git:</h5>
                                    <ul className="card-info">
                                        <li className="person">
                                            <img src={obj.owner.avatar_url} alt="avatar" width="30" height="30"/>
                                            {obj.owner.name}
                                        </li>
                                        <li><strong>Git Name:</strong> {obj.name}</li>
                                        <li><strong>Git Created at:</strong> <FormateDate setDate={obj.created_at}/>
                                        </li>
                                        <li><strong>Git last activity:</strong> <FormateDate
                                            setDate={obj.last_activity_at}/></li>
                                        <li><strong>Git visibility:</strong> {obj.visibility}</li>
                                    </ul>
                                </div>
                                <div className="card-action">
                                    <a href={obj.web_url} target="_blank" rel="noopener noreferrer">Git</a>
                                    <a href={obj.owner.web_url} target="_blank" rel="noopener noreferrer">Developer</a>
                                </div>
                            </div>
                        </div> : null
                ))}
            </div>
        </>
    )
}

export const TrashList = ({projects}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Trash and Domain name'}/>
            <div className="row">
                {Object.entries(projects).map(([key, obj], i) => (
                    (!search
                        || key.toLowerCase().indexOf(search.toLowerCase()) + 1
                        || (obj['domain'] !== false && obj['domain'].toLowerCase().indexOf(search.toLowerCase()) + 1)) ?
                        <div className="col s12 m6 l4 card-project" key={key}>
                            <div className="card">
                                <div className="card-content no-height">
                                    <ul className="card-info break-word">
                                        <li><strong>Name:</strong> {key}</li>
                                        <li><strong>Path:</strong> {obj['path']}</li>
                                        <li><strong>Domain:</strong> {obj['domain']}</li>
                                        <Size size={obj['size']}/>
                                    </ul>
                                </div>
                            </div>
                        </div> : null
                ))}
            </div>
        </>
    )
}

export const EmptyProjectList = ({projects}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Domain name'}/>
            <div className="row">
                {Object.entries(projects).map(([key, obj], i) => (
                    (!search || obj.domain.name.toLowerCase().indexOf(search.toLowerCase()) + 1) ?
                        <div className="col s12 m6 l4 card-project" key={key}>
                            <div className="card">
                                <div className="card-content no-height">
                                    <h5>Domain:</h5>
                                    <ul className="card-info">
                                        <li><strong>Domain:</strong> {obj.domain.name}</li>
                                        <li><strong>Created at:</strong> {obj.domain.created}</li>
                                        <li><strong>Database
                                            name:</strong> {(obj.database.name) ? obj.database.name : 'False'}</li>
                                        <li><strong>Database
                                            type:</strong> {(obj.database.type) ? obj.database.type : 'False'}</li>
                                        <Size size={obj.size}/>
                                    </ul>
                                </div>
                            </div>
                        </div> : null
                ))}
            </div>
        </>
    )
}

export const NoUserDatabaseList = ({databases}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Database name'}/>
            <div className="row">
                {Object.entries(databases).map(([key, obj], i) => (
                    (!search || obj.name.toLowerCase().indexOf(search.toLowerCase()) + 1) ?
                        <div className="col s12 m6 l4 card-project" key={key}>
                            <div className="card">
                                <div className="card-content no-height">
                                    <h5>Database:</h5>
                                    <ul className="card-info">
                                        <li><strong>Database name:</strong> {obj.name}</li>
                                        <li><strong>Database type:</strong> {obj.type}</li>
                                    </ul>
                                </div>
                            </div>
                        </div> : null
                ))}
            </div>
        </>
    )
}

export const NoUsedDatabaseList = ({databases}) => {
    const [search, setSearch] = useState()

    const getSearch = useCallback((search) => {
        setSearch(search)
    })

    return (
        <>
            <Search getSearch={getSearch} search={search} placeholder={'Search by Database name'}/>
            <div className="row">
                {Object.entries(databases).map(([key, obj], i) => (
                    (!search || obj.name.toLowerCase().indexOf(search.toLowerCase()) + 1) ?
                        <div className="col s12 m6 l4 card-project" key={key}>
                            <div className="card">
                                <div className="card-content no-height">
                                    <h5>Database:</h5>
                                    <ul className="card-info">
                                        <li><strong>Database name:</strong> {obj.name}</li>
                                        <li><strong>Database type:</strong> {obj.type}</li>
                                    </ul>
                                </div>
                            </div>
                        </div> : null
                ))}
            </div>
        </>
    )
}