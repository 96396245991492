import {useState, useCallback, useEffect} from 'react'
import {useHttp} from '../hooks/http.hook'
import {useMessage} from '../hooks/message.hook'
import config from '../config/config'

const storageName = 'userData'

//Todo make auth check before start work me.json
export const useAuth = () => {
    const [token, setToken] = useState(null)
    const [ready, setReady] = useState(false)
    const [user, setUser] = useState(null)
    const message = useMessage()
    const {request, error, clearError} = useHttp()

    const login = useCallback((token, user) => {
        setToken(token)
        setUser(user)

        localStorage.setItem(storageName, JSON.stringify({
            user: user, token: token
        }))
        setReady(true)
    }, [])

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError])

    const logout = useCallback(() => {
        setToken(null)
        setUser(null)
        localStorage.removeItem(storageName)
    }, [])

    const authorize = useCallback(async () => {
        //Todo URLSearchParams - maybe revrite And add V3 token
        try {
            const queryParams = new URLSearchParams(window.location.search)
            const data = await request('https://www.teamwork.com/launchpad/v1/token.json', 'POST', {
                code: queryParams.get("code"),
                client_secret: config.client_secret,
                redirect_uri: config.redirect_uri,
                client_id: queryParams.get("client_id")
            })
            login(data.access_token, data.user)
        } catch (e) {
            setReady(true)
        }
    }, [login, request])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))

        if (data && data.token) {
            login(data.token, data.user)
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            if (queryParams.get("code") && queryParams.get("client_id")) {
                authorize()
            } else {
                setReady(true)
            }
        }
    }, [login, authorize])

    return {login, logout, token, user, ready}
}
