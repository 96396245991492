import React, {useState, useCallback, useEffect} from 'react'
import {Loader} from '../components/Loader'
import {useHttp} from '../hooks/http.hook'
import config from '../config/config'
import {FormateDate} from "./FormateDate";

export const ProjectTeamTabs = ({project, tab, id}) => {
    const [people, setPeople] = useState(false)
    const {request} = useHttp()

    const getProjectPeople = useCallback(async (id) => {
        try {
            const people = await request(`https://iigglobal.teamwork.com/projects/api/v3/projects/${id}/people.json`, 'GET', null, {
                Authorization: `BASIC ${config.teamwork_auth}`
            })
            setPeople(people)
        } catch (e) {
        }
    }, [request])

    useEffect(() => {
        if (tab === 'team' && people === false) {
            getProjectPeople(id)
        }
    }, [tab])

    return (
        <>
            {(tab === 'teamwork') ? <>
                <h5>Teamwork:</h5>
                <ul className="card-info">
                    <li><strong>Name:</strong> <a
                        href={`${config.teamwork_uri}/#/projects/${project.id}/overview/activity`} target="_blank"
                        rel="noopener noreferrer">{project.name}</a>
                    </li>
                    <li><strong>Description:</strong> {project.description}</li>
                    <li><strong>Created At:</strong> <FormateDate setDate={project.createdAt}/></li>
                    <li><strong>Updated At:</strong> <FormateDate setDate={project.updatedAt}/></li>
                </ul>
            </> : null}
            {(tab === 'team') ? <>
                <h5>Team:</h5>
                {(people !== false ?
                    Object.entries(people['people']).map(([key, obj], i) => (
                        <div className="card-person">
                            <img src={obj.avatarUrl} alt="avatar" width="45" height="45"/>
                            <div>
                                <p>{obj.lastName} {obj.firstName}</p>
                                <p><strong>Email:</strong> {obj.email}</p>
                            </div>
                        </div>
                    )) : <Loader/>)}
            </> : null}
        </>
    )
}