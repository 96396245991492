import React from 'react'
import copy from 'copy-to-clipboard'

export const Copy = ({text}) => {
    return (
        <span className="copy-clipboard" value={text}
              onClick={e => copy(text, {debug: true, message: 'Press #{key} to copy',})}>
            <i class="fas fa-copy"/>
        </span>
    )
}