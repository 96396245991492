import React, {useState, useEffect, useCallback} from 'react'
import {ProjectTeamTabs} from './ProjectTeamTabs'
import {Size} from './Size'
import {Copy} from './Copy'
import {FormateDate} from './FormateDate'
import config from '../config/config'

export const ProjectItem = ({project, search, key}) => {
    const [tab, setTab] = useState('info')

    return (
        <>
            {(!search
                || project.domain.name.toLowerCase().indexOf(search.toLowerCase()) + 1
                || project.git.name.toLowerCase().indexOf(search.toLowerCase()) + 1
                || (project.teamwork !== false && project.teamwork.name.toLowerCase().indexOf(search.toLowerCase()) + 1) ) ?
                <div className="col s12 m6 l4 card-project" key={key}>
                    <div className="card">
                        <div className="card-navigation">
                            <ul className="card-tabs">
                                <li className={(tab === 'info') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                    onClick={() => setTab('info')}><i class="fas fa-info"></i></li>
                                <li className={(tab === 'git') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                    onClick={() => setTab('git')}><i class="fab fa-gitlab"></i></li>
                                {(project.teamwork !== false ?
                                    <li className={(tab === 'teamwork') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                        onClick={() => setTab('teamwork')}><i class="fas fa-tasks"></i></li> : null)}
                                {(project.teamwork !== false ?
                                    <li className={(tab === 'team') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                        onClick={() => setTab('team')}><i class="fas fa-users"></i></li> : null)}
                            </ul>
                        </div>
                        <div className="card-content">
                            {(tab === 'info') ? <>
                                <h5>Server:</h5>
                                <ul className="card-info">
                                    <li className="person">
                                        <img src={project.git.owner.avatar_url} alt="avatar" width="30" height="30"/>
                                        {project.git.owner.name}
                                    </li>
                                    <li><strong>Domain:</strong> {project.domain.name}
                                        {(project.teamwork === false ?
                                            <Copy text={project.domain.www_root.split('/').pop()}/> : null)}
                                    </li>
                                    <li><strong>Created at:</strong> {project.domain.created}</li>
                                    <li><strong>Database
                                        name:</strong> {(project.database.name) ? project.database.name : 'False'}</li>
                                    <li><strong>Database
                                        type:</strong> {(project.database.type) ? project.database.type : 'False'}</li>
                                    <Size size={project.size}/>
                                </ul>
                            </> : null}
                            {(tab === 'git') ? <>
                                <h5>Git:</h5>
                                <ul className="card-info">
                                    <li className="person">
                                        <img src={project.git.owner.avatar_url} alt="avatar" width="30" height="30"/>
                                        {project.git.owner.name}
                                    </li>
                                    <li><strong>Git Name:</strong> {project.git.name}</li>
                                      <li><strong>Git Created at:</strong> <FormateDate setDate={project.git.created_at}/></li>
                                    <li><strong>Git last activity:</strong> <FormateDate setDate={project.git.last_activity_at}/></li>
                                    <li><strong>Git visibility:</strong> {project.git.visibility}</li>
                                </ul>
                            </> : null}
                            {(project.teamwork !== false ?
                                <ProjectTeamTabs project={project.teamwork} tab={tab} id={project.teamwork.id}
                                                 key={key}/> : null)}
                        </div>
                        <div className="card-action">
                            <a href={"https://" + project.repository.domain} target="_blank"
                               rel="noopener noreferrer">Visit</a>
                            <a href={project.git.owner.web_url} target="_blank" rel="noopener noreferrer">Developer</a>
                            <a href={project.git.web_url} target="_blank" rel="noopener noreferrer">Git</a>
                            {(project.teamwork !== false ?
                                <a href={`${config.teamwork_uri}/#/projects/${project.teamwork.id}/overview/activity`}
                                   target="_blank" rel="noopener noreferrer">Teamwork</a> : null)}
                        </div>
                    </div>
                </div> : null}
        </>
    )
}

export const FtpProjectItem = ({project, search, key}) => {
    const [tab, setTab] = useState('info')

    return (
        <>
            {(!search
                || project.domain.name.toLowerCase().indexOf(search.toLowerCase()) + 1
                || (project.teamwork !== false && project.teamwork.name.toLowerCase().indexOf(search.toLowerCase()) + 1) ) ?
                <div className="col s12 m6 l4 card-project" key={key}>
                    <div className="card">
                        {(project.teamwork !== false ?
                            <div className="card-navigation">
                                <ul className="card-tabs">
                                    <li className={(tab === 'info') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                        onClick={() => setTab('info')}><i class="fas fa-info"></i></li>
                                    <li className={(tab === 'teamwork') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                        onClick={() => setTab('teamwork')}><i class="fas fa-tasks"></i></li>
                                    <li className={(tab === 'team') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                        onClick={() => setTab('team')}><i class="fas fa-users"></i></li>
                                </ul>
                            </div> : null)}
                        <div className="card-content">
                            {(tab === 'info') ? <>
                                <h5>Server:</h5>
                                <ul className="card-info">
                                    <li><strong>Domain:</strong> {project.domain.name}
                                        {(project.teamwork === false ?
                                            <Copy text={project.domain.www_root.split('/').pop()}/> : null)}
                                    </li>
                                    <li><strong>Created at:</strong> {project.domain.created}</li>
                                    <li><strong>Database
                                        name:</strong> {(project.database.name) ? project.database.name : 'False'}</li>
                                    <li><strong>Database
                                        type:</strong> {(project.database.type) ? project.database.type : 'False'}</li>
                                    <Size size={project.size}/>
                                </ul>
                            </> : null}
                            {(project.teamwork !== false ?
                                <ProjectTeamTabs project={project.teamwork} tab={tab} id={project.teamwork.id}
                                                 key={key}/> : null)}
                        </div>
                        <div className="card-action">
                            <a href={"https://" + project.domain.name} target="_blank"
                               rel="noopener noreferrer">Visit</a>
                            {(project.teamwork !== false ?
                                <a href={`${config.teamwork_uri}/#/projects/${project.teamwork.id}/overview/activity`}
                                   target="_blank" rel="noopener noreferrer">Teamwork</a> : null)}
                        </div>
                    </div>
                </div> : null}
        </>
    )
}

export const ErrorGitProjectItem = ({project, search, key}) => {
    const [tab, setTab] = useState('info')

    return (
        <>
            {(!search
                || project.domain.name.toLowerCase().indexOf(search.toLowerCase()) + 1
                || (project.teamwork !== false && project.teamwork.name.toLowerCase().indexOf(search.toLowerCase()) + 1) ) ?
                <div className="col s12 m6 l4 card-project" key={key}>
                    <div className="card">
                        {(project.teamwork !== false ?
                            <div className="card-navigation">
                                <ul className="card-tabs">
                                    <li className={(tab === 'info') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                        onClick={() => setTab('info')}><i class="fas fa-info"></i></li>
                                    <li className={(tab === 'teamwork') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                        onClick={() => setTab('teamwork')}><i class="fas fa-tasks"></i></li>
                                    <li className={(tab === 'team') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                        onClick={() => setTab('team')}><i class="fas fa-users"></i></li>
                                </ul>
                            </div> : null)}
                        <div className="card-content">
                            {(tab === 'info') ? <>
                                <h5>Git:</h5>
                                <ul className="card-info">
                                    <li><strong>Git:</strong> {project.repository.name}</li>
                                    <li><strong>Domain:</strong> {project.domain.name}</li>
                                    <li><strong>Created at:</strong> {project.domain.created}</li>
                                    <li><strong>Database
                                        name:</strong> {(project.database.name) ? project.database.name : 'False'}</li>
                                    <li><strong>Database
                                        type:</strong> {(project.database.type) ? project.database.type : 'False'}</li>
                                    <Size size={project.size}/>
                                </ul>
                            </> : null}
                            {(project.teamwork !== false ?
                                <ProjectTeamTabs project={project.teamwork} tab={tab} id={project.teamwork.id}
                                                 key={key}/> : null)}
                        </div>
                        <div className="card-action">
                            <a href={"https://" + project.repository.domain} target="_blank"
                               rel="noopener noreferrer">Visit</a>
                            {(project.teamwork !== false ?
                                <a href={`${config.teamwork_uri}/#/projects/${project.teamwork.id}/overview/activity`}
                                   target="_blank" rel="noopener noreferrer">Teamwork</a> : null)}
                        </div>
                    </div>
                </div> : null}
        </>
    )
}

export const TeamworkProjectItem = ({project, search, key}) => {
    const [tab, setTab] = useState('teamwork')

    return (
        <>
            {(!search || project.name.toLowerCase().indexOf(search.toLowerCase()) + 1) ?
                <div className="col s12 m6 l4 card-project" key={key}>
                    <div className="card">
                        <div className="card-navigation">
                            <ul className="card-tabs">
                                <li className={(tab === 'teamwork') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                    onClick={() => setTab('teamwork')}><i class="fas fa-tasks"></i></li>
                                <li className={(tab === 'team') ? 'waves-effect waves-light active' : 'waves-effect waves-light'}
                                    onClick={() => setTab('team')}><i class="fas fa-users"></i></li>
                            </ul>
                        </div>
                        <div className="card-content">
                            <ProjectTeamTabs project={project} tab={tab} id={project.id} key={key}/>
                        </div>
                        <div className="card-action">
                            <a href={`${config.teamwork_uri}/#/projects/${project.id}/overview/activity`}
                               target="_blank" rel="noopener noreferrer">Teamwork</a>
                        </div>
                    </div>
                </div> : null}
        </>
    )
}