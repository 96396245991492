import React, {useEffect, useState} from 'react'

export const StartPreloader = ({
                                   stepGit,
                                   stepPleskFiles,
                                   stepPleskRepositories,
                                   stepPleskDomains,
                                   stepTeamworkProjects,
                                   stepTeamworkTags,
                                   stepPleksDatabases
                               }) => {
    const [progress, setProgress] = useState(2)
    const [pleskProgress, setPleskProgress] = useState(false)
    const [gitProgress, setGitProgress] = useState(false)
    const [teamworkProgress, setTeamworkProgress] = useState(false)
    const [teamworkSteps, setTeamworkSteps] = useState(0)
    const [pleskSteps, setPleskSteps] = useState(0)
    const uploadTasks = [
        'Create a matrix',
        'Calculating the closest path',
        'Screaming at you',
        'Neo we need you',
        'I cache requests',
        'Looking at you',
        'Show my dick',
        'Thinking of you',
        'Looking up to heaven',
        'I\'m taking time off from work',
        'I exterminate vampires',
        'Scanning your profile',
        'Did you forget to turn on the upwork?',
        'You belong to mee',
        'You make me better',
        'ゴクラクチョウカ']
    const percent = 14
    const startPercent = 2

    useEffect(() => {
        // Calculate progress
        let pr = 0
        let countT = 0
        let countP = 0
        if (stepGit !== false) {
            pr++
        }
        if (stepPleskFiles !== false) {
            pr++
            countP++
        }
        if (stepPleskRepositories !== false) {
            pr++
            countP++
        }
        if (stepPleskDomains !== false) {
            pr++
            countP++
        }
        if (stepTeamworkProjects !== false) {
            pr++
            countT++
        }
        if (stepTeamworkTags !== false) {
            pr++
            countT++
        }
        if (stepPleksDatabases !== false) {
            pr++
            countP++
        }

        // Set Percent
        (pr === 0) ? setProgress(startPercent) : setProgress(percent * pr + startPercent)

        // Set steps
        setPleskSteps(countP)
        setTeamworkSteps(countT)

        // Set Upload end
        if (stepGit !== false) {
            setGitProgress(true)
        }
        if (stepPleskFiles !== false && stepPleskRepositories !== false && stepPleskDomains !== false && stepPleksDatabases !== false) {
            setPleskProgress(true)
        }
        if (stepTeamworkTags !== false && stepTeamworkProjects !== false) {
            setTeamworkProgress(true)
        }
    }, [stepGit, stepPleskFiles, stepPleskRepositories, stepPleskDomains, stepTeamworkProjects, stepTeamworkTags, stepPleksDatabases])

    return (<div className="start-uploader">
        <h5>{uploadTasks[Math.floor(Math.random() * uploadTasks.length)]}</h5>
        <div className="progress-line">
            <div className="progress-per" style={{width: progress + '%'}}>
                <span>{progress} %</span>
            </div>
        </div>
        {(pleskProgress === false) ?
            <div className="upload-line">
                <p>Upload Plesk data ({pleskSteps}/4)</p>
                <Dots/>
            </div> : null}
        {(gitProgress === false) ?
            <div className="upload-line">
                <p>Upload Git data (0/1)</p>
                <Dots/>
            </div> : null}
        {(teamworkProgress === false) ?
            <div className="upload-line">
                <p>Upload Teamwork data ({teamworkSteps}/2)</p>
                <Dots/>
            </div> : null}
    </div>)
}

const Dots = () => (
    <div className='preloader-dots'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
    </div>
)
